.templates-cpt-commerces {

    .intro {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        @include break(tablet) {
            flex-direction: column;
            align-items: flex-start;
        }

        svg {
            flex-shrink: 0;
            @include break(tablet) {
                width: 160px;
                height: auto;
            }
        }

        .txt {
            width: 60%;
            @include break(tablet) {
                width: 100%;
                padding-top: 60px;
            }
        }
    }

    .gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 50px;
        @include break(tablet) {
            padding-bottom: 40px;
        }

        .img {
            width: 340px;
            height: 340px;
            background-position: center;
            background-size: cover;
            margin-left: 10px;
            flex-shrink: 0;
            margin-right: 10px;
            margin-bottom: 20px;
            @include break(small-screen) {
                width: 290px;
                height: 290px;
            }
            @include break(tablet) {
                width: calc(33% - 10px);
                height: auto;
                padding-bottom: calc(33% - 10px);
                margin-left: 5px;
                margin-right: 5px;
                margin-bottom: 10px;
            }
        }
    }

    .map_section {
        padding-bottom: 0;
    }

    .share {
        padding-top: 0px;

        @include break(tablet) {
            padding-top: 0px;
        }

        .container {
            border-bottom: 1px solid $blue;
            padding-top: 60px;
            padding-bottom: 60px;
            display: flex;
            justify-content: space-between;
            
            @include break(tablet) {
                padding-top: 40px;
                padding-bottom: 40px;
            }

            .link_back {
                a {
                    display: inline-flex;
                    align-items: center;
                    text-decoration: none;
                    @include break(mobile) {
                        font-size: 14px;
                    }

                    svg {
                        margin-right: 15px;
                        @include break(mobile) {
                            margin-right: 12px;
                            width: 22px;
                            height: auto;
                        }
                    }
                }

            }

            .share_wrap {
                display: flex;
                align-items: center;

                a {
                    margin-left: 10px;
                    line-height: 100%;
                }
                span {
                    margin-right: 10px;
                    display: block;
                    line-height: 100%;
                }
            }
        }
    }

    .redirect {
        
        .container_top {
            padding-right: 35%;
            padding-bottom: 80px;
            @include break(tablet) {
                padding-right: 0%;
                padding-bottom: 40px;
            }
        }

        .container {
            display: flex;
            justify-content: space-evenly;
            align-items: flex-end;
            @include break(mobile) {
                flex-direction: column;
                align-items: center;
            }
        }

        .card {
            width: 45%;
            max-width: 525px;
            text-decoration: none;
            @include break(mobile) {
                width: 100%;
                margin-top: 40px;
    
                &:first-of-type {
                    margin-top: 0;
                }
            }
    
            .img {
                background-position: center;
                background-size: cover;
                width: 100%;
                padding-bottom: 100%;
                clip-path: ellipse(78% 78% at 50% 59%);
                transition: clip-path 0.4s;
                margin-bottom: 16px;
                background-color: $green;
            }
    
            .round {
                width: 96px;
                height: 96px;  
                background-color: $orange;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 16px;
                line-height: 110%;
                @include absolute-center(center);
                opacity: 0;
                transform: translate(-50%, -75%) scale(0.8);
                transition: all 0.4s;
            }
    
            .cat_wrap {
                margin-top: 16px;
                display: flex;
    
                .tag {
                    margin-right: 8px;
                    font-size: 10px;
                    padding: 6px 8px;
                }
            }
    
            &:hover {
                opacity: 1;
                .img {
                    clip-path: ellipse(78% 59% at 50% 59%);
                    .round {
                        opacity: 1;
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
            }

            &:first-of-type .img {
                padding-bottom: 67%;

                .round {
                    background-color: $yellow;
                }
            }
        }
    }

}
