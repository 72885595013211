.template-archive-parcours {

    .parcours .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include break(mobile) {
            flex-direction: column;
            align-items: center;
        }
    }

    .card_parcours {
        width: 32%;
        max-width: 394px;
        text-decoration: none;
        @include break(mobile) {
            width: 100%;
            margin-top: 40px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        .img {
            background-position: center;
            background-size: cover;
            width: 100%;
            padding-bottom: 100%;
            clip-path: ellipse(78% 78% at 50% 59%);
            transition: clip-path 0.4s;
            margin-bottom: 16px;
            background-color: $green;
        }

        .round {
            width: 96px;
            height: 96px;  
            background-color: $orange;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 16px;
            line-height: 110%;
            @include absolute-center(center);
            opacity: 0;
            transform: translate(-50%, -75%) scale(0.8);
            transition: all 0.4s;
        }

        .cat_wrap {
            margin-top: 16px;
            display: flex;

            .tag {
                margin-right: 8px;
                font-size: 10px;
                padding: 6px 8px;
            }
        }

        &:hover {
            opacity: 1;
            .img {
                clip-path: ellipse(78% 59% at 50% 59%);
                .round {
                    opacity: 1;
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }


        &_ghost {
            margin: 0;
            padding: 0;
            height: 0;
            @include break(mobile) {
                display: none;
            }
        }


        &:nth-child(3n + 2) .round {
            background-color: $green;
            &.border {
                border: 1px solid $blue;
            }
        }
        &:nth-child(3n + 3) .round {
            background-color: $yellow;
        }
        
        &:nth-child(3n + 1) {
            margin-top: 80px;
            @include break(mobile) {
                margin-top: 0px;
            }
        }
        &:nth-child(3n + 2) {
            margin-top: 270px;
            @include break(mobile) {
                margin-top: 40px;
            }
        }
        &:nth-child(3n + 3) {
            margin-top: -20px;
            @include break(mobile) {
                margin-top: 40px;
            }
        }
    }
    

}
