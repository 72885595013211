@import 'partials/_mixin';
@import 'partials/_function';

@import "./base/variable";
@import "./base/wordpress";
@import './base/breakpoint';
@import "./base/reset.scss";
@import './base/normalize';
@import './base/easing';
@import './base/fonts';
@import './base/layout';

@import './common/header';
@import './common/footer';
@import "./common/general";
@import "./common/anim-class";

@import './modules/module-01-header';
@import './modules/module-02-texte';
@import './modules/module-03-slider';
@import './modules/module-04-chiffres';
@import './modules/module-05-dropdown';
@import './modules/module-06-carte_rosace';
@import './modules/module-07-carte_image';
@import './modules/module-08-actualites';
@import './modules/module-09-commerces-liste';
@import './modules/module-10-map';
@import './modules/module-11-small_cards-image';
@import './modules/module-12-small_cards-white';
@import './modules/module-13-logos';
@import './modules/module-14-equipe';
@import './modules/module-15-instagram';
@import './modules/module-16-ancres';

@import './templates/cpt-commerces';
@import './templates/cpt-actualites';
@import './templates/cpt-parcours';
@import './templates/template-archive';
@import './templates/template-contact';
@import './templates/template-archive-parcours';
@import './templates/quatrecentquatre';
