.cpt-parcours {

    .quartier_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 40px;
        @include break(tablet) {
            padding-bottom: 30px;
            width: 100%;
            overflow-x: scroll;
            padding-left: 5%;
            padding-right: 5%;
            justify-content: space-between;
        }

        .tag {
            margin-left: 5px;
            margin-right: 5px;
            border-color: transparent;
            transition: all 0.4s;
            cursor: pointer;
            display: block;
            flex-shrink: 0;

            &.tag-griffintown.active, &.tag-griffintown:hover {
                border-color: $green;
            }
            &.tag-saint-henri.active, &.tag-saint-henri:hover {
                border-color: $yellow;
            }
            &.tag-petite-bourgogne.active, &.tag-petite-bourgogne:hover {
                border-color: $orange;
            }

            &.active {
                pointer-events: none;
            }
        }
    }
    .com_wrap {
        @include break(tablet) {
            width: 100%;
        } 
    }

    .quartier {
        height: 80vh;
        display: none;
        &:first-of-type() {
            display: flex;
        }
        @include break(tablet) {
            flex-direction: column-reverse;
            height: auto;
        }

        .list {
            width: 50%;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            @include break(tablet) {
                height: auto;
                width: 100%;
            }

            .card_commerce {
                width: 100%;
                height: auto;
                max-height: 300px;
                padding: 1px 30px;
                transition: all 0.4s;
                @include break(tablet) {
                    padding: 1px 20px;
                }
                cursor: pointer;
                .card_inner {
                    display: flex;
                    align-items: center;
                    padding: 29px 0;
                    border-bottom: 1px solid $blue;
                    transition: all 0.4s;
                    @include break(tablet) {
                        padding: 20px 0;
                    }

                    .img_shape {
                        max-width: 240px;
                        width: 41.5%;
                        height: auto;
                        padding-bottom: 41.5%;
                        flex-shrink: 0;
                        margin-right: 35px;
                        transition: all 0.4s;
                        clip-path: ellipse(78% 100% at 50% 59%);
                        @include break(tablet) {
                            padding-bottom: 33.5%;
                        }
                        @include break(mobile) {
                            display: none;
                        }
                    }

                    .content {
                        .nom {
                            text-transform: uppercase;
                            @include font-title;
                            padding-bottom: 5px;
                        }
                        .address {
                            font-style: italic;
                            font-size: 14px;
                            line-height: 120%;
                            padding-bottom: 16px;
                            @include break(tablet) {
                                padding-bottom: 10px;
                            }
                        }
                        .desc {
                            font-size: 16px;
                            line-height: 130%;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            display: -webkit-box;
                        }
                        .btn_wrap {
                            display: flex;
                            flex-wrap: wrap;
                            padding-top: 10px;
                            .tag {
                                margin-top: 15px;
                                font-size: 12px;
                                text-transform: none;
                                margin-right: 5px;
                                display: block;
                                @include break(tablet) {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }

                &:hover, &.active {
                    background-color: $green;
                    .card_inner {
                        border-bottom: 1px solid transparent;
                        .img_shape {
                            clip-path: ellipse(78% 59% at 50% 59%);
                        }
                    }
                }
            }
        }
        .map_container {
            width: 50%;
            @include break(tablet) {
                width: 100%;
                height: 39vh;
            }

            .map {
                width: 100%;
                height: 100%;
            }
        }

        &.petite-bourgogne .list .card_commerce {
            &:hover, &.active {
                background-color: $orange;
            }
        }
        &.saint-henri .list .card_commerce {
            &:hover, &.active {
                background-color: $yellow;
            }
        }
    }

    .module-03-slider {
        background-color: $orange;
        margin-bottom: 0;
        padding-bottom: 140px;
        @include break(tablet) {
            padding-bottom: 50px;
        }
    }
}