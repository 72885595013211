html{
  overflow-x: hidden;
  overflow-y: scroll;
  &.overflow{
    overflow: hidden;
  }
  background-color: $blue;
  color: $dark;
  &::-webkit-scrollbar { width: 0 !important };
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  min-height: -webkit-fill-available;
}

body {
  width: 100%;
  &.overflow{
    overflow: hidden;
  }
  background-color: $white;
  color: $dark;
  font-size: $font-size;
  line-height: 130%;
  @include break(mobile){
    font-size: 16px;
  }
  @include font-regular;
  font-style: normal;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 9999;
  z-index: 9999999999;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .shape {
    animation: spin 3s infinite linear;
    svg {
      width: 150px;
      animation: fadein 0.4s forwards;
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.container-top {
  padding-bottom: 80px;
  .title-h2 {
    padding-bottom: 0;
  }
  .txt {
    padding-top: 40px;
    @include break(tablet) {
      padding-top: 30px;
    }
    &:first-child {
      padding-top: 0;
    }
    
  }
  @include break(tablet) {
    padding-bottom: 60px;
  }
}

.container_btn {
  display: flex;
  justify-content: center;
}

.flex {
  display: flex;
  justify-content: space-between;
  >* {
    width: 50%;
  }
}

.mobile {
  display: none;
  @include break(mobile) {
    display: block;
  }
}
.desktop{
  @include break(mobile) {
    display: none;
  }
}

section {
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  @include break(tablet) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}


h1, h2, h3, h4 {
  a {
    color: inherit;
    text-decoration: none;
  }
}

h1, .title-h1 {
  margin: 0;
  padding: 0;
  @include font-title;
  font-size: 4.44em;
  line-height: 100%;
  text-transform: uppercase;
  @include break(tablet){
    font-size: 3em;
  }
}
h2, .title-h2 {
  margin: 0;
  padding: 0;
  @include font-title;
  font-size: 3.33em;
  line-height: 100%;
  text-transform: uppercase;
  padding-bottom: 25px;
  @include break(tablet){
    font-size: 2em;
  }
}
h3, .title-h3 {
  margin: 0;
  padding: 0;
  @include font-title;
  font-size: 2.66em;
  line-height: 100%;
  text-transform: uppercase;
  @include break(tablet){
    font-size: 1.8em;
  }
}
h4, .title-h4 {
  margin: 0;
  padding: 0;
  @include font-title;
  font-size: 2.11em;
  line-height: 100%;
  text-transform: uppercase;
  @include break(tablet){
    font-size: 1.5em;
  }
}
h5, .title-h5 {
  margin: 0;
  padding: 0;
  @include font-title;
  font-size: 1.33em;
  line-height: 100%;
  text-transform: uppercase;
}
h6, .title-h6 {
  margin: 0;
  padding: 0;
  @include font-title;
  font-size: 1.11em;
  line-height: 100%;
  text-transform: uppercase;
}


a {
  color: $blue;
  transition: all 0.4s;
   &:hover {
    opacity: 0.65;
   }
}


.btn {
  padding: 14px 20px;
  border-radius: 27px;
  background-color: $blue;
  color: $white;
  display: inline-block;
  text-decoration: none;
  margin-top: 60px;
  text-align: center;
  &:hover {
    opacity: 1;
    background-color: rgba($blue, 0.8);
   }

   @include break(tablet) {
    margin-top: 30px;
  }
}

.tag {
  padding: 6px 8px;
  border-radius: 25px;
  border: 1px solid $blue;
  text-decoration: none;
  line-height: 140%;
  font-size: 0.888em;
  text-transform: uppercase;
  text-align: center;

  &.tag_quartier {
    background-color: $blue;
    color: $white;
  }
}


.bg-center {
  background-position: center;
  background-size: cover;
}

.bg_blue {
  background-color: $blue;
  color: $white;
  a {
    color: $white;
  }
}
.bg_green {
  background-color: $green;
}
.bg_orange {
  background-color: $orange;
}
.bg_yellow {
  background-color: $yellow;
}

.align-left {
  text-align: left;
}
.align-center{
  text-align: center;
}
.align-right {
  text-align: right;
}


.img_shape {
  width: 500px;
  height: 500px;
  clip-path: ellipse(78% 59% at 50% 59%);
  position: relative;
}

.img {
  position: relative;
}

.credit  {
  height: 25px;
  background-color: $blue;
  border-radius: 25px;
  width: 25px;
  overflow: hidden;
  position: absolute;
  bottom: 15px;
  right: 15px;

  .inner {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: 25px;
    
    span, svg {
      display: block;
      flex-shrink: 0;
    }

    span {
      color: $white;
      padding-right: 11px;
      font-size: 11px;
      font-style: italic;
      display: block;
    }
    
  }
}

.grecaptcha-badge {
  visibility: hidden;
}


.back-to-top {
  position: fixed;
  right: 2.5%;
  bottom: 80px;
  z-index: 99;
  cursor: pointer;
  transition: all 0.4s;
  opacity: 0;
  transform: scale(0.7);
  pointer-events: none;

  &.visible {
    transform: scale(1);
    opacity: 1;
    pointer-events: all;

    &:hover {
      opacity: 0.7;
    }
  }
}


.google-map {
  width: 100%;
  height: 500px;
  padding-bottom: 40%;
  @include break(tablet) {
    height: 170px;
  }

}

.popup-info_btn {
  position: fixed;
  top: -88px;
  right: -88px;
  z-index: 9999;
  width: 130px;
  height: 130px;
  cursor: pointer;
  transition: all 0.4s;
  @include break(tablet) {
    top: -105px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover {
    @include break(desktop-only) {
      top: -65px;
      right: -65px;
    }
  }

  &.up{
    top: -145px;
    right: -145px;
  }

  svg circle {
    fill: $yellow;
    transition: all 0.4s;
  }

  &.open svg circle {
    fill: $orange;
  }
}
.popup-info {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9998;
  right: 0;
  .inner {
    width: 50%;
    margin-left: auto;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 10%;
    background-color: $yellow;
    @include break(tablet) {
      width: 100%;
      padding-right: 5%;
      padding-left: 5%;
    }

    .bg {
      position: absolute;
      top: 0;
      left: -28%;
      right: 0;
      bottom: 0;
      height: 100%;
      width: auto;
      z-index: 0;
    }

    .content {
      position: relative;
      z-index: 1;
      max-height: 80vh;
      overflow-y: scroll;

      .title-h1 {
        padding-bottom: 40px;
        @include break(tablet) {
          margin-top: 20px;
        }
      }
      .btn {
        margin-top: 50px;
        @include break(tablet) {
          margin-top: 30px;
        }
      }
    }
  }
}



// SUPRAMEGA
.sup_credit {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  position: relative;

  .sup_text {
    transition: all 0.4s;
  }

  .sup_logo-wrap {
    height: 1em;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,0%);
    transition: all 0.4s;
    opacity: 0;

    .sup_logo {
      height: 100%;
      position: absolute;
      width: 100%;
      opacity: 0;
      top: 50%;
      transform: translateY(-50%);

      svg {
        width: 100%;
        height: auto;
      }

      &-white {
        opacity: 1;
      }
    }
    
  }


  &:hover {
    .sup_text {
      transform: translateY(-40%);
      opacity: 0;
    }
    .sup_logo-wrap {
      transform: translate(-50%,-50%);
      opacity: 1;
    }

    .sup_logo-purple {
      animation: supHoverPurple 0.8s 0.1s forwards;
    }
    .sup_logo-green {
      animation: supHoverGreen 0.8s 0.12s forwards;
    }
    .sup_logo-yellow {
      animation: supHoverYellow 0.8s 0.14s forwards;
    }
    .sup_logo-blue {
      animation: supHoverBlue 0.8s 0.16s forwards;
    }
    .sup_logo-pink {
      animation: supHoverPink 0.8s 0.18s forwards;
    }
  }
}


@keyframes supHoverPurple {
  0% {
    opacity: 1;
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(-50%);
    opacity: 1;
  }
}

@keyframes supHoverGreen {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50%);
    opacity: 1;
  }
}

@keyframes supHoverYellow{
  0% {
    opacity: 1;
    transform: translateY(20%);
  }
  100% {
    transform: translateY(-50%);
    opacity: 1;
  }
}

@keyframes supHoverBlue{
  0% {
    opacity: 1;
    transform: translateY(40%);
  }
  100% {
    transform: translateY(-50%);
    opacity: 1;
  }
}

@keyframes supHoverPink{
  0% {
    opacity: 1;
    transform: translateY(60%);
  }
  100% {
    transform: translateY(-50%);
    opacity: 1;
  }
}