.module-07-carte_image {
    position: relative;
    overflow: initial;

    .scroll-nav {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        @include break(tablet) {
            margin-bottom: 30px;
        }
        @include break(tablet) {
            margin-bottom: 20px;
            overflow-x: scroll;
            justify-content: flex-start;
        }

        .scroll-nav_item {
            margin-left: 5px;
            margin-right: 5px;
            border-color: transparent;
            transition: all 0.4s;
            cursor: pointer;
            display: block;
            flex-shrink: 0;

            &.border-green.active, &.border-green:hover {
                border-color: $green;
            }
            &.border-yellow.active, &.border-yellow:hover {
                border-color: $yellow;
            }
            &.border-orange.active, &.border-orange:hover {
                border-color: $orange;
            }

            &.active {
                pointer-events: none;
            }
        
        }
    }

    .card_wrapper {
        position: relative;
        height: 75vh;
        max-height: 600px;
        width: 100%;
        @include break(tablet) {
            height: auto;
        }
    }

    .card {
        border-radius: 40px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 75vh;
        max-height: 600px;
        padding: 40px 80px;

        @include break(tablet) {
            height: auto;
            flex-direction: column;
            padding: 0px;
            border-radius: 20px;
        }

        .img {
            width: 45%;
            height: 100%;
            display: flex;
            align-items: center;
            @include break(tablet) {
                padding-top: 30px;
            }
    
            svg {
                height: 100%;
                width: auto;
            }
        }

        .content {
            width: 55%;
            padding-left: 80px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .inner {
                height: auto;
                max-height: 100%;
                overflow-y: scroll;
            }
            @include break(tablet) {
                width: 100%;
                padding: 30px 20px;
            }
        }
    }

    &.layout-anime {
        .container {
            position: sticky;
            top: 105px;
            left: 0;
            @include break(tablet) {
                top: 75px;
            }
        }
        .card_wrapper {
            @include break(tablet) {
                height: 75vh;
            }
        }
        .card{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            @include break(tablet) {
                height: 75vh;
            }
    
            .img {
                @include break(tablet) {
                    display: none;
                }
            }
        }
    }
}