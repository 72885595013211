.module-09-commerces-liste {

    ul {
        padding: 0;
        columns: 3;
        list-style-type: none;
        @include break(tablet) {
            columns: 2;
        }

        li a {
            text-decoration: none;
            font-size: 0.89em;
            line-height: 130%;
        }
    }
}