.module-15-instagram {

    .container-top {
        padding-bottom: 50px;
        text-align: center;
        @include break(tablet) {
            padding-bottom: 40px;
        }
        .txt {
            font-size: 1.1em;
        }
    }

    .container_img{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        max-width: 810px;
        margin-left: auto;
        margin-right: auto;

        .img {
            max-width: 250px;
            width: calc(34% - 20px);
            height: auto;
            padding-bottom: 30.9%;
            flex-shrink: 0;
            margin-top: 20px;
            @include break( mobile) {
                width: calc(33% - 2.5px);
                margin-top: 5px;
            }
        }
    }

    .container_txt {
        text-align: center;
        padding-top: 30px;
        font-size: 1.4em;
        line-height: 100%;
        @include break( mobile) {
            font-size: 1.25em;
        }
    }
}