footer {
    overflow-x: hidden;
    overflow-y: visible;
    padding-top: 60px;
    padding-bottom: 60px;

    .footer_top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 60px;
        border-bottom: 1px solid rgba(255,255,255,0.5);

        .content {
            width: 52%;
            @include break(tablet) {
                width: 65%;
            }
            @include break(mobile) {
                width: 100%;
            }
            .title-h3 {
                padding-top: 12px;
                @include break(mobile) {
                    font-size: 2em;
                }
            }
        }
        .subscribe_form {
            padding-top: 90px;
            @include break(mobile) {
                padding-top: 60px;
            }

            .mc-field-group {
                padding-top: 22px;
            }
            .form_content {
                display: flex;
                position: relative;
                align-items: flex-end;
                position: relative;
                @include break(mobile) {
                    flex-direction: column;
                    align-items: flex-start;
                    >div {
                        width: 100%;
                    }
                }
            }
            div.mce_inline_error {
                margin: 0!important;
                padding: 5px 10px!important;
                background-color: transparent!important;
                font-weight: bold!important;
                z-index: 1!important;
                position: absolute!important;
                color: #6B0505!important;
                font-size: 10px!important;
                @include break(mobile) {
                    padding: 2px 0px !important;
                }
            }
            .response {
                margin: 0 !important;
                padding: 18px 0px !important;
                background-color: transparent !important;
                font-weight: bold !important;
                z-index: 1 !important;
                color: #fff !important;
                position: absolute !important;
                font-size: 14px !important;
                width: 100%;
                text-align: left;
                @include break(mobile) {
                    padding: 5px 0px !important;
                    font-size: 12px !important;
                    line-height: 100%;
                }
            }

            input {
                outline: none;
                background-color: transparent;
                border-radius: 0;
                border: none;
                border-bottom: 1px solid $white;
                padding: 12px 10px;
                font-size: 14px;
                width: 350px;
                flex-shrink: 0;
                margin-right: 40px;
                color: $white;
                font-weight: 400;
                @include break(mobile) {
                    width: 100%;
                }

                &::placeholder {
                    font-style: italic;
                }

                &[type=submit] {
                    width: auto;
                    padding: 14px 20px;
                    border-radius: 27px;
                    border:1px solid $white;
                    background-color: $blue;
                    color: $white;
                    display: inline-block;
                    text-decoration: none;
                    transition: all 0.4s;
                    cursor: pointer;
                    @include break(mobile) {
                        margin-top: 50px;
                    }
                    &:hover {
                        opacity: 1;
                        background-color: rgba(white, 1);
                        color: $blue;
                    }
                }
            }
        }
        .shape {
            flex-shrink: 0;

            svg {
                width: 270px;
                height: auto;
            }
        }
    }

    .footer_bottom {
        padding-top: 60px;
        display: flex;
        justify-content: space-between;
        @include break(tablet) {
            flex-wrap: wrap;
        }

        .col {
            width: 20%;
            padding-right: 5%;
            @include break(tablet) {
                width: 45%;
                padding-right: 0%;
                margin-bottom: 25px;
                flex-grow: 2;
            }

            .col-title {
                font-size: 14px;
                text-transform: uppercase;
            }

            a {
                display: block;
                padding-top: 16px;
                font-size: 0.8em;
                line-height: 110%;
                text-decoration: none;
                @include break(tablet) {
                    padding-top: 8px;
                }
            }
        }

        .col-social {
            width: fit-content;
            .social {
                display: flex;
                padding-top: 16px;
                @include break(tablet) {
                    padding-top: 8px;
                }
                a {
                    padding-top: 0;
                    margin-right: 10px;
    
                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .footer_copy {
        text-align: right;
        font-size: 14px;
        padding-top: 50px;
        @include break(tablet) {
            text-align: left;
            padding-top: 25px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            small {
                width: 50%;
            }
            .shape {
                flex-shrink: 0;
                svg {
                    width: 90px;
                    height: auto;
                }
            }
        }
        .sup_credit:hover {
            opacity: 1;
        }
    }
}