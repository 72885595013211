.template-contact {

    form {
        width: 100%;
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;

        .row, .row >p {
            display: flex;
            justify-content: space-between;
            width: 100%;
            @include break(tablet) {
               flex-direction: column;
            }
            
            label {
                position: relative;
                width: 100%;
                margin-bottom: 10px;
                margin-right: 16px;
                flex-shrink: 1;
                &:last-of-type {
                    margin-right: 0;
                }

                .placeholder {
                    position: absolute;
                    color: $white;
                    top: calc(24px + 0.59em);
                    transform: translateY(-50%);
                    left: 26px;
                    z-index: 2;
                    pointer-events: none;
                    line-height: 100%;
                    transition: all 0.4s;
                }

                &.active {
                    .placeholder {
                        top: calc(8px + 0.59em);
                        font-size: 10px;
                    }
                }
            }
            br {
                display: none;
            }


        }

        input, textarea {
            flex-grow: 1;
            border-radius: 20px;
            background-color: $blue;
            padding: 24px 26px;
            border: none;
            line-height: 100%;
            color: $white;
            outline: none;
            width: 100%;
            margin-bottom: 5px;
        }

        input[type=submit] {
            padding: 14px 20px;
            border-radius: 27px;
            display: inline-flex;
            width: auto;
            margin-top: 30px;
        }

        .wpcf7-response-output {
            border: none!important;
            padding-left: 0!important;
        }
    }
}