.module-06-carte_rosace {

    .card {
        border-radius: 40px;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        height: 600px;
        position: relative;
        padding: 80px;
        @include break(tablet) {
            border-radius: 20px;
            flex-direction: column;
            height: auto;
            padding: 0px;
        }

        .shape {
            position: absolute;
            top: -12.5%;
            left: 0;
            transform: translateX(-35%);
            height: 125%;
            svg {
                height: 100%;
                width: auto;
            }
            @include break(tablet) {
                margin-top: -45%;
                height: auto;
                width: 100%;
                position: relative;
                top: auto;
                left: auto;
                transform: translateX(0%);
                svg {
                    height: auto;
                    width: 100%;
                }
            }
        }

        .content {
            width: 47%;
            overflow-y: scroll;
            @include break(tablet) {
                width: 100%;
                padding: 40px 20px;
            }
        }
    }
}