.module-14-equipe {

    .container-top {
        padding-right: 45%;
        @include break(tablet) {
            padding-right: 0%;
        }
    }

    .container_team {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: -40px;
        @include break(mobile) {
            flex-direction: column;
            margin-bottom: -30px;
        }

        .team_card {
            width: 32%;
            max-width: 394px;
            margin-bottom: 40px;
            @include break(tablet) {
                width: 48%;
            }
            @include break(mobile) {
                width: 100%;
                margin-bottom: 30px;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            .img {
                background-position: center;
                background-size: cover;
                width: 100%;
                padding-bottom: 100%;
                clip-path: ellipse(78% 78% at 50% 59%);
                transition: clip-path 0.4s;
                margin-bottom: 16px;
                background-color: $green;
            }

            .content {
                display: flex;

                .content_linkedin {
                    width: 30px;
                    margin-left: 20px;
                }
                .content_txt {
                    width: 100%;
                    flex-shrink: 1;
                }
            }

            .title-h5 {
                margin-bottom: 10px;
            }
            .poste {
                padding-bottom: 3px;
            }

            .info, .link {
                font-size: 14px;
                padding-top: 5px;
                display: block;
                line-height: 110%;
            }
            .link {
                text-decoration: none;
                word-break: break-word;
            }

            &_ghost {
                margin: 0;
                padding: 0;
                height: 0;
                @include break(mobile) {
                    display: none;
                }
            }
        }
    }
}