.module-16-ancres {
    overflow-anchor: none; 
    overflow: initial;
    margin-top: 100px;
    padding-top: 88px;
    position: sticky;
    position: -webkit-sticky;
    background-color: $white;
    top: 0;
    left: 0;
    padding-bottom: 20px;
    z-index: 99;
    transform: translateY(0px);
    transition: all 0.4s;
    @include break(tablet) {
        margin-top: 0px;
    }

    &.up {
        transform: translateY(-70px);
    }
    
    .container_ancres {
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        align-items: center;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 0px;
        @include break(small-screen) {
            max-width: 90%;
        }
        @include break(tablet) {
            max-width: 90%;
            padding-bottom: 0px;
        }

        a {
            flex-shrink: 0;
            margin-left: 15px;
            margin-right: 15px;
            text-decoration: none;
            line-height: 140%;
            font-size: 0.888em;
            text-transform: uppercase;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }

}