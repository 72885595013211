.module-04-chiffres {
    .container_chiffre {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include break(tablet) {
          flex-direction: column;
        }

        .chiffre {
            position: relative;
            margin-bottom: 20px;
            .content {
                @include absolute-center(center);
                text-align: center;
                padding-bottom: 35px;

                .txt {
                    font-size: 1.11em;
                    padding-top: 16px;
                    line-height: 120%;
                    @include break(tablet) {
                        font-size: 1em;
                    }
                }
            }

            &.chiffre-2 {
                .content {
                    transform: translate(-40%, -50%);
                }
            }
        }
    }
}