.module-08-actualites {
    .container-top {
        padding-right: 45%;
        @include break(tablet) {
            padding-right: 0%;
        }
    }
    
    .card {
        width: 32%;
        max-width: 394px;
        text-decoration: none;
        @include break(mobile) {
            width: 100%;
            margin-top: 40px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        .img {
            background-position: center;
            background-size: cover;
            width: 100%;
            padding-bottom: 100%;
            clip-path: ellipse(78% 78% at 50% 59%);
            transition: clip-path 0.4s;
            margin-bottom: 16px;
            background-color: $green;
        }

        .round {
            width: 96px;
            height: 96px;  
            background-color: $orange;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 16px;
            line-height: 110%;
            @include absolute-center(center);
            opacity: 0;
            transform: translate(-50%, -75%) scale(0.8);
            transition: all 0.4s;
        }

        .date {
            font-size: 0.889em;
            margin-bottom: 16px;
            color: $orange;
            line-height: 100%;
        }

        .cat_wrap {
            margin-top: 16px;
            display: flex;

            .tag {
                margin-right: 8px;
                font-size: 10px;
                padding: 6px 8px;
            }
        }

        &:hover {
            opacity: 1;
            .img {
                clip-path: ellipse(78% 59% at 50% 59%);
                .round {
                    opacity: 1;
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }
    }

    .card:nth-child(2) .round {
        background-color: $green;
        &.border {
            border: 1px solid $blue;
        }
    }
    .card:nth-child(3) .round {
        background-color: $yellow;
    }

    .card_ghost {
        height: 0;
        margin: 0;
        padding: 0;
        @include break(mobile) {
            display: none;
        }
    }


// LAYOUT MOSAIQUE
    &.layout-mosaique {
        .container_card {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @include break(mobile) {
                flex-direction: column;
                align-items: center;
            }

            .card:nth-child(1) {
                margin-top: 80px;
                @include break(mobile) {
                    margin-top: 0px;
                }
            }
            .card:nth-child(2) {
                margin-top: 270px;
                @include break(mobile) {
                    margin-top: 40px;
                }
            }
            .card:nth-child(3) {
                margin-top: -20px;
                @include break(mobile) {
                    margin-top: 40px;
                }
            }
        }
    }


// LAYOUT COLONNE
    &.layout-col {
        .container_card {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @include break(mobile) {
                flex-direction: column;
                align-items: center;
            }
        }
    }


// LAYOUT ROW
    &.layout-row {
        clip-path: ellipse(125% 59% at 50% 59%);
        background-color: $orange;
        margin-top: 70px;
        margin-bottom: 70px;
        padding-top: 260px;
        padding-bottom: 248px;
        @include break(tablet) {
            clip-path: ellipse(170% 59% at 50% 59%);
            padding-top: 120px;
            padding-bottom: 124px;
        }
        @include break(tablet) {
            clip-path: ellipse(190% 59% at 50% 59%);
        }

        .container-top {
            padding-right: 45%;
            @include break(tablet) {
                padding-right: 0%;
            }
        }

        .container_card {
            padding-left: 5%;
            padding-right: 5%;
        }
        .card_row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 160px;
            @include break(tablet) {
                flex-direction: column;
                margin-bottom: 80px;
            }

            .img {
                width: 47.5%;
                background-position: center;
                background-size: cover;
                padding-bottom: 47.5%;
                background-color: $green;
                @include break(tablet) {
                    margin-bottom: 16px;
                    width: 100%;
                    padding-bottom: 100%;
                }
            }

            .content {
                width: 47.5%;
                @include break(tablet) {
                    width: 100%;
                }

                .date {
                    padding-bottom: 12px;
                }

                .desc {
                    margin-top: 22px;
                }

                .cat_wrap {
                    margin-top: 28px;
                    display: flex;
                    @include break(mobile) {
                        margin-top: 14px;
                    }
        
                    .tag {
                        margin-right: 8px;
                        font-size: 10px;
                        padding: 6px 8px;
                    }
                }

                .btn {
                    @include break(mobile) {
                        display: block;
                        text-align: center;
                    }  
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &:nth-child(2) {
                flex-direction: row-reverse;
                @include break(tablet) {
                    flex-direction: column;
                }
            }
        }
    }

    .container_btn {
        padding-top: 30px;
    }

}