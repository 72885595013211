.module-13-logos {

    .container-top {
        padding-right: 45%;
        padding-bottom: 40px;
        @include break(tablet) {
            padding-right: 0%;
            padding-bottom: 20px;
        }
    }

    .container_logos {
        margin-left: -2.5%;
        margin-right: -2.5%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .logo {
            width: 20.5%;
            padding-left: 2.5%;
            padding-right: 2.5%;
            margin-bottom: 16px;
            @include break(mobile) {
                width: 45%;
                margin-bottom: 5px;
            }

            &_ghost {
                padding: 0;
                margin: 0;
                height: 0;
                @include break(mobile) {
                   display: none;
                }
            }
        }
    }
}