.module-11-small_cards-image {

    .container_card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -30px;
        @include break(tablet) {
            flex-direction: column;
            margin-bottom: -20px;
        }

        .small_card {
            width: 49%;
            border-radius: 20px;
            background-color: $green;
            padding: 30px;
            min-height: 308px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            @include break(tablet) {
                min-height: 0;
                height: auto;
                width: 100%;
                margin-bottom: 20px;
                align-items: flex-start;
            }

            .img {
                width: 35%;
                padding-bottom: 44%;
                flex-shrink: 0;
                margin-right: 20px;
                @include break(mobile) {
                    display: none;
                }
            } 

            .txt {
                font-size: 16px;
                line-height: 130%;
                margin-top: 20px;
            }

            .btn {
                padding: 7px 10px;
                font-size: 12px;
                margin-top: 24px;
            }

            &:nth-child(3n + 2 ) {
                background-color: $orange;
            }
            &:nth-child(3n) {
                background-color: $yellow;
            }
        }
    }

}