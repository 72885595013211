.module-10-map {


    .container_map {
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        margin-bottom: 40px;
        @include break(mobile) {
            margin-bottom: 30px;
        }
    }

    .container_col {
        display: flex;
        justify-content: space-evenly;
        @include break(mobile) {
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .col {
            width: 33%;
            flex-grow: 2;
            padding-left: 2.5%;
            padding-right: 2.5%;
            @include break(mobile) {
                width: 45%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 20px;
            }

            &:first-of-type {
                padding-left: 0;
            }
            &:last-of-type {
                padding-right: 0;
            }
        }
    }
}