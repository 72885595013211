.template-archive {
    padding-bottom: 70px;
    @include break(tablet) {
        padding-bottom: 50px;
    }

// HEADER -> modules/module-01-header.scss
.container-top {
    padding-right: 35%;
    @include break(tablet) {
        padding-right: 0;
    }
}
.card_row {
    padding: 60px 80px;
    border-radius: 40px;
    background-color: $yellow;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 160px;
    @include break(tablet) {
        padding: 40px 20px;
        border-radius: 20px;
        flex-direction: column;
    }

    .img {
        width: 47.5%;
        background-position: center;
        background-size: cover;
        padding-bottom: 47.5%;
        background-color: $green;
        height: auto;
        @include break(tablet) {
            margin-bottom: 16px;
            width: 100%;
            padding-bottom: 100%;
        }
    }

    .content {
        width: 47.5%;
        @include break(tablet) {
            width: 100%;
        }

        .date {
            padding-bottom: 12px;
        }

        .desc {
            margin-top: 22px;
        }

        .cat_wrap {
            margin-top: 28px;
            display: flex;
            @include break(mobile) {
                margin-top: 14px;
            }

            .tag {
                margin-right: 8px;
                font-size: 10px;
                padding: 6px 8px;
            }
        }

        .btn {
            @include break(mobile) {
                display: block;
                text-align: center;
            }  
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &:nth-child(2) {
        flex-direction: row-reverse;
        @include break(tablet) {
            flex-direction: column;
        }
    }
}


    .filter_container {
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        z-index: 10;
        @include break(tablet) {
            flex-direction: column;
            align-items: flex-end;
        }

        .dropdown_filter {
            position: relative;
            margin-left: 12px;
            margin-right: 12px;
            max-width: 345px;
            min-width: 220px;
            transition: all 0.4s;
            @include break(tablet) {
                width: 100%;
                max-width: 100%;
                margin-bottom: 10px;
                margin-left: 0px;
                margin-right: 0px;
            }
            .filter_label {
                display: flex;
                align-items: center;
                padding: 6px 15px;
                cursor: pointer;

                .filter_title {
                    width: 100%;
                    flex-shrink: 1;
                    overflow-x: hidden;
                    white-space: nowrap;
                }
                svg {
                    flex-shrink: 0;
                    margin-left: 15px;
                }
            }

            .dropdown-container {
                display: none;
                z-index: 5;
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                margin-top: 8px;
                border-radius: 15px;
                background: $white;
                box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
                padding-right: 15px;
                overflow: hidden;
                @include break(tablet) {
                    position: relative;
                }

                &_inner {
                    padding: 15px;
                    width: 100%;
                    height: 200px;
                    overflow-y: scroll;
                    overflow-x: hidden;

                    .tag {
                        font-size: 14px;
                        width: fit-content;
                        margin-bottom: 12px;
                        cursor: pointer;
                        transition: all 0.4s;

                        &:hover, &.active {
                            background-color: $blue;
                            color: $white;
                        }

                        &_quartier:hover, &.active {
                            background-color: rgba($blue, 0.7);
                        }
                    }
                }
            }

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        .reset {
            margin-left: 12px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            align-items: center;
            @include break(tablet) {
                margin-top: 10px;
                margin-left: 0px;
                margin-right: 0px;
            }

            div {
                padding-top: 3px;
                line-height: 100%;
            }
        }
    }

    
    .card_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 70px;
        @include break(mobile) {
            flex-direction: column;
            align-items: center;
        }

        .card {
            width: 32%;
            max-width: 394px;
            text-decoration: none;
            margin-bottom: 50px;
            @include break(mobile) {
                width: 100%;
    
                &:first-of-type {
                    margin-top: 0;
                }
            }
    
            .img {
                background-position: center;
                background-size: cover;
                width: 100%;
                padding-bottom: 100%;
                clip-path: ellipse(78% 78% at 50% 59%);
                transition: clip-path 0.4s;
                margin-bottom: 16px;
                background-color: $green;
            }
    
            .round {
                width: 96px;
                height: 96px;  
                background-color: $orange;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 16px;
                line-height: 110%;
                @include absolute-center(center);
                opacity: 0;
                transform: translate(-50%, -75%) scale(0.8);
                transition: all 0.4s;
            }
    
            .date {
                font-size: 0.889em;
                margin-bottom: 16px;
                color: $orange;
                line-height: 100%;
            }
    
            .cat_wrap {
                margin-top: 16px;
                display: flex;
                flex-wrap: wrap;
    
                .tag {
                    margin-right: 8px;
                    font-size: 10px;
                    padding: 6px 8px;
                    margin-bottom: 10px;
                }
            }
    
            &:hover {
                opacity: 1;
                .img {
                    clip-path: ellipse(78% 59% at 50% 59%);
                    .round {
                        opacity: 1;
                        transform: translate(-50%, -50%) scale(1);
                    }
                }
            }
        }
    
        .card:nth-child(3n + 2) .round {
            background-color: $green;
            &.border {
                border: 1px solid $blue;
            }
        }
        .card:nth-child(3n + 3) .round {
            background-color: $yellow;
        }
    
        .card_ghost {
            height: 0;
            margin: 0;
            padding: 0;
            @include break(mobile) {
                display: none;
            }
        }
    }

    .error_message {
        display: none;
        padding-top: 40px;
        padding-bottom: 40px;
        @include break(mobile) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .title-h5 {
            text-transform: none;
        }
    }

    .load-more {
        cursor: pointer;
        transition: all 0.4s;
    }
}