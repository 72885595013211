
//FONT
@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Bold.eot');
  src: url('../fonts/WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/WorkSans-Bold.woff2') format('woff2'),
      url('../fonts/WorkSans-Bold.woff') format('woff'),
      url('../fonts/WorkSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Medium.eot');
  src: url('../fonts/WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/WorkSans-Medium.woff2') format('woff2'),
      url('../fonts/WorkSans-Medium.woff') format('woff'),
      url('../fonts/WorkSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'mortise';
  src: url('../fonts/mortise-medium.eot');
  src: url('../fonts/mortise-medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/mortise-medium.woff2') format('woff2'),
      url('../fonts/mortise-medium.woff') format('woff'),
      url('../fonts/mortise-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}



@mixin font-regular {
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
}
@mixin font-bold {
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
}
@mixin font-title {
  font-family: 'mortise', serif;
  font-weight: 500;
}
