header {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.4s;

  &.up {
    transform: translateY(-100%);
  }

  &.open {
    background-color: $white;
    &.up {
      transform: translateY(0%);
    }
  }
  
  .nav_container {
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding-top: 30px;
    padding-bottom: 30px;
    @include break(small-screen) {
      padding-top: 22px;
      padding-bottom: 22px;
    }
  }
  
  .logo {
    width: 145px;
    height: auto;
    @include break(tablet) {
      width: 120px;
    }
  }
  

  // DESKTOP
  .nav_desktop {
    display: flex;
    @include break(tablet) {
      display: none;
    }
    .menu-item {
      padding: 12px 15px;
      border-radius: 22px;
      text-decoration: none;
      font-size: 0.888em;
      background-color: $blue;
      color: $white;
      transition: all;
      margin-left: 10px;
      line-height: 100%;
      display: inline-flex;
      align-items: baseline;
      transition: all 0.4s;
      cursor: pointer;
      height: fit-content;
      @include break(small-screen) {
        padding: 10px 13px;
        font-size: 0.8em;
      }

      &:hover{
        background-color: rgba($blue, 0.8);
      }

      span {
        margin-right: 10px;
      }
    }

    .lang {
      display: flex;
      align-items: center;
      padding-left: 15px;
      ul, .wpml-ls-statics-shortcode_actions {
        margin: 0;
        padding: 0;
      }
      .wpml-ls-item a{
        margin: 0;
        padding: 12px 15px;
        border-radius: 22px;
        text-decoration: none;
        font-size: 0.888em;
        background-color: $blue;
        color: $white;
        transition: all;
        margin-left: 15px;
        line-height: 100%;
        display: inline-flex;
        align-items: baseline;
        transition: all 0.4s;
        cursor: pointer;
        height: fit-content;
        text-transform: uppercase;
        @include break(small-screen) {
          padding: 10px 13px;
          font-size: 0.8em;
        }
      }
    }
  }


  .nav_desktop-drop {
    background-color: $white;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 0px 0px 40px 40px;
    box-shadow: 4px 10px 14px 0px  rgba(0, 0, 0, 0.10);
    display: none;
    height: 0;
    overflow: hidden;

    .nav-drop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: none;

      .container {
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        justify-content: space-between;
        height: 100%;

        .links {
          width: 50%;
          padding-left: 5%;
          height: 100%;
          overflow-y: scroll;
          overflow-x: hidden;

          a {
            display: block;
            text-decoration: none;

            &.lvl1 {
              @extend .title-h4;
              margin-bottom: 35px!important;
            }
            &.lvl2 {
              @extend .title-h5;
              padding-bottom: 14px!important;
              margin-left: 20px!important;
              opacity: 0.7; 
              text-transform: none!important;
              &:hover {
                opacity: 1;
              }
            }

            &.lvl2 + .lvl1 {
              margin-top: 50px!important;
            }
          }
        }

        .img_shape {
          height: 100%;
          width: 53vh;
          position: relative;
          overflow: hidden;

          .img {
            background-color: $green;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            &:first-of-type {
              opacity: 1;
            }
          }
        }
      }
    }

    .close {
      position: absolute;
      z-index: 3;
      bottom: 35px;
      left: 2.5%;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }


  //MOBILE


  .nav_burger {
    display: none;
    position: relative;
    cursor: pointer;
    @include break(tablet) {
      display: block;
    }
    .burger {
      height: 20px;
      pointer-events: none;
      width: 32px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      transform: translateX(0);
      transition: all 0.4s;
      div {
        background-color: $blue;
        width: 100%;
        height: 2px;
      }
    }
    .close {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      height: 42px;
      width: 42px;
      transition: all 0.4s;
    }
  }

  &.open .nav_burger{
    .burger {
      opacity: 0;
      height: 42px;
    }
    .close {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  .nav_mobile-drop {
    background-color: $white;
    position: absolute;
    top: 99.5%;
    left: 0;
    right: 0;
    border-radius: 0px 0px 40px 40px;
    box-shadow: 4px 10px 14px 0px  rgba(0, 0, 0, 0.10);
    display: none;
    height: 0;
    max-height: calc(100vh - 80px);
    overflow: hidden;
    border-radius: 0px 0px 30px 30px;

    .container {
      padding-top: 7px;
      padding-bottom: 80px;
      max-height: calc(100vh - 80px);
      overflow-y: scroll;

      .menu-item {
        border-bottom: 1px solid $blue;
        display: block;

        .menu-item_title {
          padding-top: 15px;
          padding-bottom: 15px;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .plus {
            width: 14px;
            height: 14px;
            flex-shrink: 0;
            position: relative;

            span {
              position: absolute;
              top:50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 3px;
              width: 100%;
              transition: all 0.4s;
              background-color: $blue;

              &:first-of-type {
                transform: translate(-50%, -50%) rotate(90deg);
              }
            }
          }

        }
        
        &.open .plus span:first-of-type {
          transform: translate(-50%, -50%) rotate(0deg);
        }

        .nav-drop {
          overflow: hidden;
          height: 0;
          .nav-drop_inner{
            padding-top: 20px;
            padding-bottom: 25px;
          } 
          a {
            display: block;
            text-decoration: none;

            &.lvl1 {
              @include font-title;
              font-size: 1.15em;
              margin-bottom: 8px;
            }
            &.lvl2 {
              @include font-title;
              font-size: 1em;
              margin-bottom: 8px;
            }

            &.lvl2 + .lvl1 {
              margin-top: 20px!important;
            }
          }
        }
      }

      .lang {
        display: flex;
        align-items: center;
        padding-top: 30px;
        ul, .wpml-ls-statics-shortcode_actions {
          margin: 0;
          padding: 0;
        }
        .wpml-ls-item a{
          margin: 0;
          text-decoration: none;
          font-size: 14px;
          line-height: 100%;
          display: block;
          width: auto;
          padding: 0;
          margin: 0;
          text-transform: uppercase;
        }
      }

    }
  }

  .nav-mobile_close {
    position: absolute;
    top: 99.5%;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: -1;
    display: none;
  }
  
}
