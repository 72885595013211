.module-03-slider {
    background-color: $yellow;
    margin-top: 70px;
    margin-bottom: 70px;
    @include break(tablet) {
        margin-top: 50px;
        margin-bottom: 50px;
    }

// SLIDER - SIMPLE
    &.layout-simple {
        
        .slider_container {
            display: flex;
            align-items: center;
            @include break(mobile) {
                flex-wrap: wrap;
                justify-content: flex-end;
            }

            .arrow_left, .arrow_right {
                flex-shrink: 0;
                cursor: pointer;
                @include break(mobile) {
                    margin-top: 20px;
                }

                svg {
                    width: 38px;
                    @include break(mobile) {
                        width: 25px;
                    }
                }
            }
            .arrow_left {
                margin-right: 30px;
                @include break(mobile) {
                    order: 2;
                    margin-right: 15px;
                }
            }
            .arrow_right {
                margin-left: 30px;
                @include break(mobile) {
                    order: 3;
                    margin-left: 15px;
                }
            }

            .slider {
                width: 100%;
                padding-bottom: 44.5%;
                position: relative;
                clip-path: ellipse(78% 59% at 50% 59%);
                overflow: hidden;
                @include break(mobile) {
                    padding-bottom: 50%;
                }

                .slide {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: none;

                    &:first-of-type {
                        display: block;
                    }
                }
            }
        }
    }


// SLIDER - COMPLEXE
    &.layout-complexe {
        clip-path: ellipse(125% 59% at 50% 59%);
        padding-top: 260px;
        padding-bottom: 248px;
        @include break(tablet) {
            clip-path: ellipse(170% 59% at 50% 59%);
            padding-top: 120px;
            padding-bottom: 124px;
        }
        @include break(tablet) {
            clip-path: ellipse(190% 59% at 50% 59%);
        }

        .container-top {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @include break(mobile) {
                flex-direction: column;
                padding-bottom: 40px;
            }

            .content {
                width: 65%;
                @include break(mobile) {
                    width: 100%;
                }
            }

            .wrap_arrow {
                flex-shrink: 0;
                display: flex;
                @include break(mobile) {
                    margin-top: 40px;
                }
            }
            .arrow_right, .arrow_left {
                cursor: pointer;
                margin-left: 25px;
                transition: all 0.3s;

                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }

        .slider_container {
            display: flex;
            align-items: center;
            width: 100%;
            overflow-y: hidden;
            overflow-x: scroll;
            &::-webkit-scrollbar { width: 0 !important };
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;

            .slide {
                width: 400px;
                flex-shrink: 0;
                margin-right: 25px;
                margin-left: 25px;
                text-decoration: none;
                scroll-snap-align: center;
                @include break(tablet) {
                    width: 45%;
                    margin-right: 2.5%;
                    margin-left: 2.5%;
                }
                @include break(mobile) {
                    width: 95%;
                }

                .img {
                    background-color: $green;
                    width: 100%;
                    padding-bottom: 100%;
                    margin-bottom: 16px;
                    clip-path: ellipse(78% 78% at 50% 59%);
                    position: relative;
                    transition: clip-path 0.4s;
                }

                .round {
                    width: 96px;
                    height: 96px;  
                    background-color: $orange;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 16px;
                    line-height: 110%;
                    @include absolute-center(center);
                    opacity: 0;
                    transform: translate(-50%, -75%) scale(0.8);
                    transition: all 0.4s;
                }

                &:nth-child(2n) {
                    .img {
                        padding-bottom: 50%;
                    }
                }
            }

            a.slide {
                &:hover {
                    opacity: 1;
                    .img {
                        clip-path: ellipse(78% 59% at 50% 59%);
                        .round {
                            opacity: 1;
                            transform: translate(-50%, -50%) scale(1);
                        }
                    }
                }
                &:nth-of-type(3n+1) .round {
                    background-color: $green;
                    &.border {
                        border: 1px solid $blue;
                    }
                }
                &:nth-of-type(3n+3) .round {
                    background-color: $yellow;
                }
            }
        }

    }
}