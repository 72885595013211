.module-12-small_cards-white {
    .container_card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -30px;
        @include break(tablet) {
            flex-direction: column;
            margin-bottom: -20px;
        }

        .small_card_white {
            width: 32%;
            border-radius: 20px;
            border: 1px solid $blue;
            padding: 22px;
            height: 210px;
            overflow-x: hidden;
            overflow-y: scroll;
            display: flex;
            justify-content: space-between;
            margin-bottom: 22px;
            @include break(tablet) {
                width: 100%;
                margin-bottom: 18px;
                align-items: flex-start;
            } 

            .txt {
                font-size: 16px;
                line-height: 130%;
                margin-top: 20px;
            }

            .btn {
                padding: 7px 10px;
                font-size: 12px;
                margin-top: 24px;
            }

            &_ghost {
                border: none;
                height: 0;
                margin: 0;
                padding: 0;
                @include break(tablet) {
                    display: none;
                }
            }
        }
    }
}