.module-05-dropdown {
    .container-top {
        padding-right: 45%;
        @include break(tablet) {
            padding-right: 0%;
        }
    }

    .dropdown {
        cursor: pointer;
        display: flex;
        padding-bottom: 50px;
        padding-top: 50px;
        border-bottom: 1px solid $blue;
        justify-content: space-between;
        @include break(tablet) {
            flex-wrap: wrap;
            padding-bottom: 25px;
            padding-top: 25px;
        }

        &:first-of-type {
            padding-top: 0;
        }

        .titre {
            width: 80%;
            margin-right: 60px;
            flex-shrink: 0;
            transition: width 0.3s;
            @include break(tablet) {
                width: calc(86% - 40px) !important;
                margin-bottom: 10px;
            }

            &.anime {
                word-break: break-all;
            }
        }

        .content {
            cursor: initial;
            width: 0%;
            flex-shrink: 1;
            margin-right: 10%;
            overflow: hidden;
            height: 0;
            @include break(tablet) {
                order: 3;
            }

            ul, ol {
                padding-left: 1.1em;
                li {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }

            .btn {
                margin-top: 30px;
                @include break(tablet) {
                    margin-top: 20px;
                }
            }
        }

        .plus {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            flex-grow: 0;
            position: relative;
            cursor: pointer;

            div {
                background-color: $blue;
                width: 100%;
                height: 4px;
                transition: all 0.3s;
                @include absolute-center(center);

                &.plus_2 {
                    transform: translate(-50%, -50%) rotate(90deg);
                }
            }
        }

        &.dropdown_open {
            .titre {
                width: 30%;
            }
            .plus div.plus_2 {
                transform: translate(-50%, -50%) rotate(0deg);
            }
        }
    }

}