.module-02-texte {
    .title-h2, .title-h4 {
        padding-bottom: 35px;
        @include break(mobile){
            padding-bottom: 25px;
        }
    }

    .btn {
        margin-top: 60px;
        @include break(mobile){
            margin-top: 30px;
        }
    }

// LAYOUT - 1 COLONNE
    &.layout-col1 {
        .content {
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
            @include break(tablet){
                padding-left: 5%;
                padding-right: 5%;
            }
            @include break(mobile){
                padding-left: 0%;
                padding-right: 0%;
            }
        }
    }


// LAYOUT - 2 COLONNE TITRE-TEXTE
    &.layout-col2.type-titre {
        .col1 {
            width: 55%;
            padding-bottom: 80px;
            @include break(tablet){
                width: 80%;
                padding-bottom: 60px;
            }
            @include break(mobile){
                width: 100%;
            }
            .title-h2, .title-h4 {
                padding-bottom: 0;
            }
        }
        .col2 {
            width: 55%;
            margin-left: auto;
            @include break(tablet){
                width: 80%;
            }
            @include break(mobile){
                width: 100%;
            }
        }
    }


// LAYOUT - 2 COLONNE ROSACE
    &.layout-col2.type-rosace {
        .container {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @include break(tablet){
                align-items: flex-start;
            }
            @include break(mobile){
                flex-direction: column;
            }
            .col1 {
                flex-shrink: 0;
                width: 308px;
                margin-right: 5%;
                @include break(mobile){
                    width: 160px;
                    margin-right: 0%;
                    svg {
                        width: 160px;
                        height: auto;
                    }
                }
            }
            .col2 {
                flex-shrink: 1;
                width: 60%;
                @include break(mobile){
                    width: 100%;
                }
            }
        }
    }


// LAYOUT - 2 COLONNE IMAGE
    &.layout-col2.type-image {
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include break(tablet){
                flex-direction: column-reverse;
            }
            .col1 {
                width: 50%;
                flex-shrink: 2;
                @include break(tablet){
                    width: 100%;
                }
            }
            .col2 {
                width: 45%;
                flex-shrink: 1;
                @include break(tablet){
                    width: 100%;
                    margin-bottom: 40px;
                }

                .img_shape {
                    width: 100%;
                    height: auto;
                    padding-bottom: 115%;
                    @include break(tablet){
                        width: 600px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    @include break(mobile){
                        width: 100%;
                        height: auto;
                        padding-bottom: 107%;
                    }
                }
            }
        }
    }

// LAYOUT - 2 COLONNE IMAGE REVERSE
    &.layout-col2.type-image-reverse {
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include break(tablet){
                flex-direction: column;
            }
            .col2 {
                width: 50%;
                flex-shrink: 2;
                @include break(tablet){
                    width: 100%;
                }
            }
            .col1 {
                width: 45%;
                flex-shrink: 1;
                @include break(tablet){
                    width: 100%;
                    margin-bottom: 40px;
                }

                .img_shape {
                    width: 100%;
                    height: auto;
                    padding-bottom: 115%;
                    @include break(tablet){
                        width: 600px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    @include break(mobile){
                        width: 100%;
                        height: auto;
                        padding-bottom: 107%;
                    }
                }
            }
        }
    }


// LAYOUT - BIG IMAGE
    &.layout-big-image {
        .title-h2 {
            padding-right: 25%;
            @include break(mobile){
                padding-right: 0;
            }
        }

        .txt_wrap {
            width: 50%;
            padding-bottom: 120px;
            @include break(mobile){
                width: 100%;
                padding-bottom: 0px;
            }
        }

        .btn {
            margin-top: 60px;
            margin-bottom: -120px;
            display: table;
            @include break(mobile){
                margin-top: 30px;
                margin-bottom: 0px;
            }
        }

        .img {
            width: 50%;
            margin-left: auto;
            display: block;
            height: auto;
            margin-top: -55px;
            position: relative;
            @include break(mobile){
                width: 100%;
                margin-top: 40px;
            }
        } 
    }
}