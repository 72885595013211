.module-01-header {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 70px;
    @include break(tablet) {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 50px;
    }

// LAYOUT - BASIC
    &.layout-basic {
        .container {
            display: flex;
            justify-content: space-between;
            padding-top: 120px;
            padding-bottom: 90px;
            min-height: 85vh;
            @include break(tablet){
                padding-top: 115px;
                padding-bottom: 80px;
                flex-direction: column-reverse;
                min-height: 70vh;
            }

            .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 50%;
                @include break(tablet){
                    width: 100%;
                    margin-top: 60px;
                }

                .txt {
                    padding-top: 25px;
                }
            }

            .shape {
                width: 45%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                @include break(tablet){
                    width: 100%;

                    svg {
                        width: 160px;
                        height: 160px;
                    }
                }
            }
        }
    }


// LAYOUT - IMAGE
    &.layout-image {
        .container {
            display: flex;
            justify-content: space-between;
            padding-top: 145px;
            padding-bottom: 120px;
            min-height: 100vh;
            @include break(tablet){
                padding-top: 100px;
                padding-bottom: 80px;
                flex-direction: column;
            }

            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-right: 5%;
                width: 50%;
                @include break(tablet){
                    width: 100%;
                }

                .top {
                    display: flex;
                    margin-bottom: 30px;

                    a {
                        span {
                            margin-left: 15px;
                        }
                        text-decoration: none;
                    }
                }

                .shape {
                    margin-left: auto;
                    padding-top: 25px;
                    @include break(tablet){
                        svg {
                            width: 90px;
                            height: 90px;
                        }
                    }
                }
                .txt {
                    padding-top: 25px;
                }
            }

            .img {
                width: 50%;
                @include break(tablet){
                    width: 100%;
                    height: auto;
                    padding-top: 40px;
                }
                .img_shape {
                    width: 100%;
                    height: auto;
                    padding-bottom: 105%;
                    @include break(tablet){
                        height: auto;
                        padding-bottom: 90%;
                    }
                }
            }
        }
    }


    &.layout-img2 {
        .link_back {
            a {
                display: inline-flex;
                align-items: center;
                text-decoration: none;
                @include break(tablet){
                    margin-bottom: 40px;
                }
                @include break(mobile) {
                    font-size: 14px;
                }

                svg {
                    margin-right: 15px;
                    @include break(mobile) {
                        margin-right: 12px;
                        width: 22px;
                        height: auto;
                    }
                }
            }

        }

        .container .content {

           .cat_wrap {
            display: flex;

            .tag {
                margin-top: 40px;
                margin-right: 10px;
            }
           }
        }

        .content_container {
            @include break(tablet){
                padding-bottom: 40px;
            }
        }

        .container_col {
            padding-top: 120px;
            border-top: 1px solid $blue;
            min-height: 0;
            @include break(tablet){
                padding-top: 40px;
                flex-wrap: wrap;
                flex-direction: row;
            }

            .col {
                width: fit-content;
                max-width: 250px;
                padding-right: 2.5%;
                font-size: 0.88em;
                @include break(tablet){
                    width: 47.5%;
                    max-width: 100%;
                    padding-right: 0%;
                    flex-grow: 1;
                    margin-top: 20px;
                    &:first-of-type {
                        margin-top: 0;
                    }
                }
                @include break(mobile){
                    width: 100%;
                }

                h6 {
                    @include font-bold;
                    padding-bottom: 15px;
                }

                a {
                    text-decoration: none;
                }

               .social {
                display: flex;
                margin-top: 12px;
                a {
                    margin-left: 8px;
                    &:first-of-type {
                        margin-left: 0;
                    }
                }
               }

               &:last-of-type {
                padding-right: 0;
               }
            }
        }
    }


// LAYOUT - HOME
    &.layout-home {
        padding-top: 140px;
        padding-bottom: 140px;
        @include break(tablet){
            padding-top: 115px;
            padding-bottom: 80px;
        }

        .quartier_wrap {
            display: flex;
            justify-content: space-between;
            padding-bottom: 40px;

            .space {
                width: 2%;
                flex-shrink: 0;
                @include break(tablet){
                    width: 1%;
                }
            }
            .quartier {
                width: 32%;
                position: relative;
                height: 60vh;
                transition: all 0.4s;
                @include break(tablet){
                    height: 40vh;
                }
                @include break(mobile){
                    height: 25vh;
                }

                .img_shape {
                    width: 100%;
                    height: 100%;
                }

                .filter {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    transition: all 0.4s;
                    background-color: rgba($color: $blue, $alpha: 0);
                }

                .title-quartier {
                    @include absolute-center(center);
                    z-index: 2;
                    @include font-title;
                    font-size: 1.8em;
                    line-height: 100%;
                    text-transform: uppercase;
                    color: $white;
                    text-align: center;
                    width: 125%;
                    transition: all 0.4s;
                    opacity: 0;
                    @include break(tablet){
                        font-size: 1.1em;
                    }
                    @include break(mobile){
                        font-size: 1em;
                        width: 90%;
                    }
                }

                &:hover {
                    width: 55%;
                    .title-quartier {
                        opacity: 1;
                    }
                    .filter {
                        background-color: rgba($color: $blue, $alpha: 0.3);
                    }
                }
            }
        }

        .container.text {
            text-align: center;

            .title-h4 {
                margin-left: auto;
                margin-right: auto;
                display: block;
                width: 80%;
                max-width: 711px;
                padding-top: 140px;
                @include break(tablet){
                    padding-top: 80px;
                    width: 100%;
                }
            }

            .btn {
                margin-top: 70px;
                @include break(tablet){
                    margin-top: 40px;
                }
            }
        }
    }



    // HEADER ARCHIVE

    &.archive-header {
        .container_arch {
            display: flex;
            justify-content: space-between;
            padding-top: 120px;
            padding-bottom: 90px;
            min-height: 100vh;
            @include break(tablet){
                padding-top: 115px;
                padding-bottom: 80px;
                flex-direction: column;
                min-height: 70vh;
            }

            .content {
                padding-left: 5%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-right: 5%;
                width: 50%;
                @media only screen and (min-width: 1480px) {
                    padding-left: calc((100vw - 1280px) / 2);
                }

                @include break(tablet){
                    width: 100%;
                    margin-bottom: 60px;
                }

                .txt {
                    padding-top: 25px;
                }
            }

            .img {
                position: relative;
                width: 50%;
                @include break(tablet){
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    align-items: center;
                }

                .img_shape {
                    position: absolute;
                    overflow: hidden;
                    @include break(tablet){
                        position: relative;
                        flex-shrink: 0;
                    }
                    .bg_img{
                        @include absolute-center(center);
                        width: 100%;
                        height: 100%;
                        transition: all 0.6s;
                        opacity: 0;
                    }

                    &:hover {
                        .bg_img{
                            opacity: 1;
                        }
                    }
                }

                .shape1 {
                    left: 0;
                    top: 0;
                    width: 42%;
                    height: auto;
                    padding-bottom: 40%;
                    transform: rotate(25deg);
                    background-color: $blue;
                    z-index: 3;
                    @include break(tablet){
                        margin-top: 85px;
                    }
                    .bg_img{
                        width: 145%;
                        height: 145%;
                        transform: translate(-50%,-50%) rotate(-30deg) scale(1.3);
                    }

                    &:hover {
                        .bg_img{
                            transform: translate(-50%,-50%) rotate(-25deg) scale(1);
                        }
                    }
                }
                .shape3 {
                    right: 0;
                    bottom: -90px;
                    width: 90%;
                    height: auto;
                    padding-bottom: 32%;
                    clip-path: ellipse(70% 59% at 50% 59%);
                    background-color: $yellow;
                    z-index: 2;
                    @include break(tablet){
                        bottom: -80px;
                        margin-top: -90px;
                    }
                    .bg_img{
                        transform: translate(-50%,-50%) rotate(5deg) scale(1.3);
                    }
                    &:hover {
                        .bg_img{
                            transform: translate(-50%,-50%) rotate(0deg) scale(1);
                        }
                    }
                }

                .shape2 {
                    right: 0%;
                    top: -150px;
                    width: 35%;
                    height: auto;
                    padding-bottom: 88%;
                    transform: rotate(-25deg);
                    background-color: $orange;
                    clip-path: ellipse(94% 59% at 50% 38%);
                    z-index: 1;
                    @include break(tablet){
                        top: 0px;
                    }
                    .bg_img{
                        width: 205%;
                        height: 150%;
                        transform: translate(-58%, -57%) rotate(30deg) scale(1.3);
                    }
                    &:hover {
                        .bg_img{
                            transform: translate(-58%, -57%) rotate(25deg) scale(1);
                        }
                    }
                }
            }
        }
    }
}